<template>
  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[78vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="">
          <img src="@/assets/images/404.png" class="mb-8 w-auto md:w-[400px] mx-auto" alt="" />
          <h2 class="mb-2 text-[32px] md:text-[50px] text-brand-black leading-[110%] font-semibold">We lost that page....</h2>
          <p class="text-light-grey/50 text-[18px] md:text-[25px] mb-[3rem]">
            Sorry the page you are looking for doesnt exist or has been moved. Here are some helpful links:
          </p>

          <div class="text-center">
            <router-link to="/" class="inline-block px-8 py-3 rounded-xl bg-brand-black text-[16px] text-white">Visit our Homepage</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
